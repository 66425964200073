import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import {
    useCreateOrderMutation,
    useGetOrderDeliveryProvidersQuery,
    useGetOrderPaymentProvidersQuery,
    useGetOrderPaymentStatusQuery,
    useGetOrderQuery,
    useGetOrdersQuery,
    useGetOrderStatusQuery,
    useRetryPaymentMutation,
    useSendPayUTokenMutation,
    useValidateOrderMutation,
} from '../redux/api/order';
import { useDispatch } from '../redux/hooks';
import {
    resetNewOrderStep,
    selectOrderState,
    setNewOrderDiscountCode,
    setNewOrderMutation,
    setNewOrderStepMode,
    setNewOrderStepValues,
    setNewOrderUserConsent,
    setNewOrderUserNote,
} from '../redux/slices/order.slice';
import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';

export type TUseOrderQueries =
    | 'single'
    | 'list'
    | 'delivery'
    | 'payment'
    | 'payment-status-interval'
    | 'order-status-interval';

interface IUseOrderConfig {
    queries?: TUseOrderQueries[];
    fixedKeys?: { create?: string; validate?: string; retryPayment?: string };
    orderUid?: string | undefined | null;
}

export const useOrder = ({
    queries = [],
    fixedKeys,
    orderUid,
}: IUseOrderConfig | undefined = {}) => {
    const dispatch = useDispatch();
    const t = useTranslations('RTKQueryApi');
    const orderState = useSelector(selectOrderState);

    const search = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(search);
    const orderUUID = urlParams.get('order');

    const skipOrderQuery = !queries.includes('single') || !orderUUID;
    const skipOrdersQuery = !queries.includes('list');
    const skipDeliveryProvidersQuery = !queries.includes('delivery');
    const skipPaymentProvidersQuery = !queries.includes('payment');
    const skipPaymentStatusQuery = !queries.includes('payment-status-interval');
    const skipOrderStatusQuery = !queries.includes('order-status-interval') || !orderUid;

    const getOrderQuery = useGetOrderQuery(orderUUID, { skip: skipOrderQuery });
    const getOrdersQuery = useGetOrdersQuery(undefined, { skip: skipOrdersQuery });
    const getOrderDeliveryProvidersQuery = useGetOrderDeliveryProvidersQuery(
        { items: orderState.mutation.items, discountCodes: orderState.mutation.discountCodes },
        { skip: skipDeliveryProvidersQuery }
    );
    const getOrderPaymentProvidersQuery = useGetOrderPaymentProvidersQuery(
        { items: orderState.mutation.items },
        { skip: skipPaymentProvidersQuery }
    );

    const paymentId = getOrderQuery.data?.payments[0].uuid;
    const getOrderPaymentStatusQuery = useGetOrderPaymentStatusQuery(paymentId, {
        skip: skipPaymentStatusQuery,
        pollingInterval: 1000,
    });

    const getOrderStatusQuery = useGetOrderStatusQuery(orderUid, {
        skip: skipOrderStatusQuery,
        pollingInterval: 1000,
    });

    const createConfig = fixedKeys?.create ? { fixedCacheKey: fixedKeys.create } : {};
    const validateConfig = fixedKeys?.validate ? { fixedCacheKey: fixedKeys.validate } : {};
    const retryPaymentConfig = fixedKeys?.retryPayment
        ? { fixedCacheKey: fixedKeys.retryPayment }
        : {};

    const [create, createOrderMutation] = useCreateOrderMutation(createConfig);
    const [validate, validateOrderMutation] = useValidateOrderMutation(validateConfig);
    const [retryPayment, retryPaymentMutation] = useRetryPaymentMutation(retryPaymentConfig);
    const [sendPayUToken, sendPayUTokenMutation] = useSendPayUTokenMutation();

    const singleErrors = useMemo(() => {
        return getRtkQueryErrors(getOrderQuery.error, t.errors);
    }, [getOrderQuery.error, t.errors]);
    const listErrors = useMemo(() => {
        return getRtkQueryErrors(getOrdersQuery.error, t.errors);
    }, [getOrdersQuery.error, t.errors]);
    const deliveryProvidersErrors = useMemo(() => {
        return getRtkQueryErrors(getOrderDeliveryProvidersQuery.error, t.errors);
    }, [getOrderDeliveryProvidersQuery.error, t.errors]);
    const paymentProvidersErrors = useMemo(() => {
        return getRtkQueryErrors(getOrderPaymentProvidersQuery.error, t.errors);
    }, [getOrderPaymentProvidersQuery.error, t.errors]);
    const createErrors = useMemo(() => {
        return getRtkQueryErrors(createOrderMutation.error, t.errors);
    }, [createOrderMutation.error, t.errors]);
    const validateErrors = useMemo(() => {
        return getRtkQueryErrors(validateOrderMutation.error, t.errors);
    }, [t.errors, validateOrderMutation.error]);
    const retryPaymentErrors = useMemo(() => {
        return getRtkQueryErrors(retryPaymentMutation.error, t.errors);
    }, [retryPaymentMutation.error, t.errors]);
    const sendPayUTokenErrors = useMemo(() => {
        return getRtkQueryErrors(sendPayUTokenMutation.error, t.errors);
    }, [sendPayUTokenMutation.error, t.errors]);
    const paymentStatusErrors = useMemo(() => {
        return getRtkQueryErrors(getOrderPaymentStatusQuery.error, t.errors);
    }, [getOrderPaymentStatusQuery.error, t.errors]);
    const orderStatusErrors = useMemo(() => {
        return getRtkQueryErrors(getOrderStatusQuery.error, t.errors);
    }, [getOrderStatusQuery.error, t.errors]);

    return {
        new: orderState,
        isNewOrderComplete: Object.values(orderState.steps).every((step) => step.isComplete),
        single: {
            data: getOrderQuery.data,
            isFetching: getOrderQuery.isFetching,
            isLoading: getOrderQuery.isLoading,
            isSuccess: getOrderQuery.isSuccess,
            isError: getOrderQuery.isError,
            errors: singleErrors,
        },
        list: {
            data: getOrdersQuery.data,
            isFetching: getOrdersQuery.isFetching,
            isLoading: getOrdersQuery.isLoading,
            isSuccess: getOrdersQuery.isSuccess,
            isError: getOrdersQuery.isError,
            errors: listErrors,
        },
        deliveryProviders: {
            data: getOrderDeliveryProvidersQuery.data,
            isFetching: getOrderDeliveryProvidersQuery.isFetching,
            isLoading: getOrderDeliveryProvidersQuery.isLoading,
            isSuccess: getOrderDeliveryProvidersQuery.isSuccess,
            isError: getOrderDeliveryProvidersQuery.isError,
            errors: deliveryProvidersErrors,
        },
        paymentProviders: {
            data: getOrderPaymentProvidersQuery.data,
            isFetching: getOrderPaymentProvidersQuery.isFetching,
            isLoading: getOrderPaymentProvidersQuery.isLoading,
            isSuccess: getOrderPaymentProvidersQuery.isSuccess,
            isError: getOrderPaymentProvidersQuery.isError,
            errors: paymentProvidersErrors,
        },
        paymentStatus: {
            data: getOrderPaymentStatusQuery.data,
            isFetching: getOrderPaymentStatusQuery.isFetching,
            isLoading: getOrderPaymentStatusQuery.isLoading,
            isSuccess: getOrderPaymentStatusQuery.isSuccess,
            isError: getOrderPaymentStatusQuery.isError,
            errors: paymentStatusErrors,
        },
        status: {
            data: getOrderStatusQuery.data,
            isFetching: getOrderStatusQuery.isFetching,
            isLoading: getOrderStatusQuery.isLoading,
            isSuccess: getOrderStatusQuery.isSuccess,
            isError: getOrderStatusQuery.isError,
            errors: orderStatusErrors,
        },
        create: {
            fetch: create,
            data: createOrderMutation.data,
            isLoading: createOrderMutation.isLoading,
            isSuccess: createOrderMutation.isSuccess,
            isError: createOrderMutation.isError,
            errors: createErrors,
        },
        validate: {
            fetch: validate,
            data: validateOrderMutation.data,
            isLoading: validateOrderMutation.isLoading,
            isSuccess: validateOrderMutation.isSuccess,
            isError: validateOrderMutation.isError,
            errors: validateErrors,
        },
        retryPayment: {
            fetch: retryPayment,
            data: retryPaymentMutation.data,
            isLoading: retryPaymentMutation.isLoading,
            isSuccess: retryPaymentMutation.isSuccess,
            isError: retryPaymentMutation.isError,
            errors: retryPaymentErrors,
        },
        sendPayUToken: {
            fetch: sendPayUToken,
            data: sendPayUTokenMutation.data,
            isLoading: sendPayUTokenMutation.isLoading,
            isSuccess: sendPayUTokenMutation.isSuccess,
            isError: sendPayUTokenMutation.isError,
            errors: sendPayUTokenErrors,
        },
        setStepMode: (arg: Parameters<typeof setNewOrderStepMode>[0]) => {
            dispatch(setNewOrderStepMode(arg));
        },
        setStepValues: (arg: Parameters<typeof setNewOrderStepValues>[0]) => {
            dispatch(setNewOrderStepValues(arg));
        },
        setMutation: (arg: Parameters<typeof setNewOrderMutation>[0]) => {
            dispatch(setNewOrderMutation(arg));
        },
        resetStep: (arg: Parameters<typeof resetNewOrderStep>[0]) => {
            dispatch(resetNewOrderStep(arg));
        },
        setUserConsent: (arg: Parameters<typeof setNewOrderUserConsent>[0]) => {
            dispatch(setNewOrderUserConsent(arg));
        },
        setDiscountCode: (arg: Parameters<typeof setNewOrderDiscountCode>[0]) => {
            dispatch(setNewOrderDiscountCode(arg));
        },
        setUserNote: (arg: Parameters<typeof setNewOrderUserNote>[0]) => {
            dispatch(setNewOrderUserNote(arg));
        },
    };
};
