import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import { wrapper, grid, container, help, loader } from './result.module.scss';
import { ISection } from '../../models/section.model';
import { getUrlParamValue } from '../../utils/get-url-param-value';
import { useOrder } from '../../hooks/use-order';
import { EPaymentStatus } from '../../models/payment.model';

import Section from '../hoc/section';
import AnimatedCheckmarkOutlined from '../atoms/animated-checkmark-outlined';
import Info from '../organisms/info';
import Markdown from '../hoc/markdown';
import Loader from '../atoms/loader';

export interface ISectionResultProps {
    section: ISection;
    TitleTag?: React.ElementType;
}

export default function Result({ section, TitleTag = 'h2' }: ISectionResultProps) {
    const { sectionId, content, css, style } = section;
    const [
        titleSuccess,
        textSuccess,
        helpTextSuccess,
        titleError,
        textError,
        helpTextError,
        subtitleSuccess,
        subtitleError,
    ] = content.texts;
    const [buttonSuccess, buttonError] = content.buttons;

    const isError = !!getUrlParamValue('error');
    const id = getUrlParamValue('id');
    const mode = getUrlParamValue('mode');

    const [orderId, setOrderId] = useState(id);
    const [isLoading, setIsLoading] = useState(true);
    const order = useOrder({
        queries: ['order-status-interval'],
        orderUid: mode && !isError ? orderId : null,
    });
    const orderPaymentStatus = order.status.data?.payments[0]?.status;
    const isOrderStatusFetchingError = order.status.isError;

    const title = isError ? titleError : titleSuccess;
    const subtitle = isError ? subtitleError : subtitleSuccess;
    const text = isError ? textError : textSuccess;
    const helpText = isError ? helpTextError : helpTextSuccess;
    const button = isError ? buttonError : buttonSuccess;

    useEffect(() => {
        if (isError || mode !== 'order') {
            setIsLoading(false);
            return;
        }
        const errorUrl = `${window.location.pathname}${window.location.search}&error=true`;
        if (isOrderStatusFetchingError) {
            navigate(errorUrl, { replace: true });
            return;
        }
        if (!orderPaymentStatus || orderPaymentStatus < EPaymentStatus.paid) return;
        if (orderPaymentStatus === EPaymentStatus.paid) {
            setIsLoading(false);
            setOrderId(null);
            return;
        }
        if (orderPaymentStatus > EPaymentStatus.paid) {
            navigate(errorUrl, { replace: true });
        }
    }, [isError, isOrderStatusFetchingError, mode, orderPaymentStatus]);

    return (
        <Section
            sectionId={sectionId}
            className={wrapper}
            classes={{ container: grid }}
            css={css}
            style={style}
        >
            {isLoading && <Loader className={loader} />}
            {!isLoading && (
                <Info className={container} title={title} button={button} TitleTag={TitleTag}>
                    {({ checkmarkClassName, descriptionClassName }) => (
                        <>
                            {!isError && (
                                <AnimatedCheckmarkOutlined className={checkmarkClassName} />
                            )}
                            <div className={descriptionClassName}>
                                <p>
                                    {subtitle} {id && <strong>{id}</strong>}
                                </p>
                                {text && <Markdown>{text}</Markdown>}
                            </div>
                            {helpText && <Markdown className={help}>{helpText}</Markdown>}
                        </>
                    )}
                </Info>
            )}
        </Section>
    );
}
