export interface IPayment {
    paymentId: string | number;
    uuid: string;
    paymentMethod: string;
    canRetry: boolean;
    redirectUrl: string | null;
    status: EPaymentStatus;
    statusName: string;
    isMain: boolean;
}

export enum EPaymentStatus {
    new,
    started = 5,
    pending = 10,
    paid = 100,
    failed = 200,
    partiallyPaid = 201,
    overpaid = 202,
    refunded = 250,
    canceled = 255,
}
